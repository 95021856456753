import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/su/Personal/Projects/Playground/lottie-react/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import LottieExamples from "./LottieExamples";
import LottieWithInteractivity from "./LottieWithInteractivity.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "lottie"
    }}>{`Lottie`}</h1>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <LottieExamples mdxType="LottieExamples" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Lottie from "lottie-react";
import groovyWalkAnimation from "./groovyWalk.json";

const Example = () => {
  return <Lottie animationData={groovyWalkAnimation} />;
};

export default Example;
`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <h3 {...{
      "id": "animationdata"
    }}><inlineCode parentName="h3">{`animationData`}</inlineCode></h3>
    <p>{`A JSON Object with the exported animation data`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`Type: Object
Default: none
Required: ☑
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "loop"
    }}><inlineCode parentName="h3">{`loop`}</inlineCode></h3>
    <p>{`Set it to true for infinite amount of loops, or pass a number to specify how many times should the last segment played be looped (`}<a parentName="p" {...{
        "href": "https://github.com/airbnb/lottie-web/issues/1450"
      }}>{`More info`}</a>{`)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`Type: Boolean | Number
Default: true
Required: ☐
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "autoplay"
    }}><inlineCode parentName="h3">{`autoplay`}</inlineCode></h3>
    <p>{`If set to true, animation will play as soon as it's loaded`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`Type: Boolean
Default: true
Required: ☐
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "initialsegment"
    }}><inlineCode parentName="h3">{`initialSegment`}</inlineCode></h3>
    <p>{`Expects an array of length 2. First value is the initial frame, second value is the final frame. If this is set, the animation will start at this position in time instead of the exported value from AE`}</p>
    <p><strong parentName="p">{`Gotcha`}</strong>{`: The animation will re-run every time the segment array changes.  Therefore, to ensure that the animation behaves as expected, you must provide a stable array.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`Type: Array
Default: none
Required: ☐
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "oncomplete"
    }}><inlineCode parentName="h3">{`onComplete`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "onloopcomplete"
    }}><inlineCode parentName="h3">{`onLoopComplete`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "onenterframe"
    }}><inlineCode parentName="h3">{`onEnterFrame`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "onsegmentstart"
    }}><inlineCode parentName="h3">{`onSegmentStart`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "onconfigready"
    }}><inlineCode parentName="h3">{`onConfigReady`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "ondataready"
    }}><inlineCode parentName="h3">{`onDataReady`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "ondatafailed"
    }}><inlineCode parentName="h3">{`onDataFailed`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "onloadedimages"
    }}><inlineCode parentName="h3">{`onLoadedImages`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "ondomloaded"
    }}><inlineCode parentName="h3">{`onDOMLoaded`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "ondestroy"
    }}><inlineCode parentName="h3">{`onDestroy`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "style"
    }}><inlineCode parentName="h3">{`style`}</inlineCode></h3>
    <p>{`Style object that applies to the animation wrapper (which is a div)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`Type: Object
Default: none
Required: ☐
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "lottieref"
    }}><inlineCode parentName="h3">{`lottieRef`}</inlineCode></h3>
    <p>{`Expects a React ref object in which interaction methods will be stored`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`Type: React.RefObject
Default: none
Required: ☐
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "interactivity"
    }}><inlineCode parentName="h3">{`interactivity`}</inlineCode></h3>
    <p>{`Interactivity params to sync animation with either scroll or cursor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`Type: Object
Default: none
Required: ☐
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "reacthtmlpropshtmldivelement"
    }}><inlineCode parentName="h3">{`React.HTMLProps<HTMLDivElement>`}</inlineCode></h3>
    <p>{`Alongside the props listed above, the `}<inlineCode parentName="p">{`<Lottie/>`}</inlineCode>{` component also extends `}<inlineCode parentName="p">{`React.HTMLProps<HTMLDivElement>`}</inlineCode>{`. This allows you to pass props to the container `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Lottie from "lottie-react";
import groovyWalkAnimation from "./groovyWalk.json";

const Example = () =>
  <Lottie
    animationData={groovyWalkAnimation}
    aria-aria-labelledby="use lottie animation"
  />
};

export default Example;
`}</code></pre>
    <h2 {...{
      "id": "interaction-methods"
    }}>{`Interaction methods`}</h2>
    <p>{`These methods are designed to give you more control over the Lottie animation, and fill in the gaps left by the props:`}</p>
    <h3 {...{
      "id": "play"
    }}><inlineCode parentName="h3">{`play()`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "stop"
    }}><inlineCode parentName="h3">{`stop()`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "pause"
    }}><inlineCode parentName="h3">{`pause()`}</inlineCode></h3>
    <hr></hr>
    <h3 {...{
      "id": "setspeedspeed"
    }}><inlineCode parentName="h3">{`setSpeed(speed)`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`speed: 1 is normal speed
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "gotoandplayvalue-isframe"
    }}><inlineCode parentName="h3">{`goToAndPlay(value, isFrame)`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`value: numeric value.
isFrame: defines if first argument is a time based value or a frame based (default false).
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "gotoandstopvalue-isframe"
    }}><inlineCode parentName="h3">{`goToAndStop(value, isFrame)`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`value: numeric value.
isFrame: defines if first argument is a time based value or a frame based (default false).
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "setdirectiondirection"
    }}><inlineCode parentName="h3">{`setDirection(direction)`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`direction: 1 is forward, -1 is reverse.
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "playsegmentssegments-forceflag"
    }}><inlineCode parentName="h3">{`playSegments(segments, forceFlag)`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`segments: array. Can contain 2 numeric values that will be used as first and last frame of the animation. Or can contain a sequence of arrays each with 2 numeric values.
forceFlag: boolean. If set to false, it will wait until the current segment is complete. If true, it will update values immediately.
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "setsubframeusesubframes"
    }}><inlineCode parentName="h3">{`setSubframe(useSubFrames)`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`useSubFrames: If false, it will respect the original AE fps. If true, it will update on every requestAnimationFrame with intermediate values. Default is true.
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "getdurationinframes"
    }}><inlineCode parentName="h3">{`getDuration(inFrames)`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`inFrames: If true, returns duration in frames, if false, in seconds
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "destroy"
    }}><inlineCode parentName="h3">{`destroy()`}</inlineCode></h3>
    <h3 {...{
      "id": "calling-the-methods"
    }}>{`Calling the methods`}</h3>
    <p>{`To use the interaction methods listed above, pass a reference object to the Lottie component by using the `}<inlineCode parentName="p">{`ref`}</inlineCode>{` prop (see the React documentation to learn more about `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/refs-and-the-dom.html"
      }}>{`Ref`}</a>{` or `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#useref"
      }}>{`useRef`}</a>{` hook):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Lottie from "lottie-react";
import groovyWalkAnimation from "./groovyWalk.json";

const Example = () => {
  const lottieRef = useRef();

  return <Lottie lottieRef={lottieRef} animationData={groovyWalkAnimation} />;
};

export default Example;
`}</code></pre>
    <p>{`You can then use the interaction methods like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`...
lottieRef.current.pause();
...
`}</code></pre>
    <h2 {...{
      "id": "interactivity-1"
    }}>{`Interactivity`}</h2>
    <p>{`To sync animation with either scroll or cursor, you can pass the interactivity
object.`}</p>
    <p>{`For more information please navigate to `}<strong parentName="p">{`useLottieInteractivity`}</strong>{` hook`}</p>
    <LottieWithInteractivity mdxType="LottieWithInteractivity" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Lottie from "lottie-react";
import robotAnimation from "./robotAnimation.json";

const style = {
  height: 300,
};

const interactivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.2],
      type: "stop",
      frames: [0],
    },
    {
      visibility: [0.2, 0.45],
      type: "seek",
      frames: [0, 45],
    },
    {
      visibility: [0.45, 1.0],
      type: "loop",
      frames: [45, 60],
    },
  ],
};

const Example = () => {
  return (
    <Lottie
      animationData={robotAnimation}
      style={style}
      interactivity={interactivity}
    />
  );
};

export default Example;
`}</code></pre>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p>{`Soon :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      